import throttle from 'lodash/throttle'
import { useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { Coordinates, Language } from '@/core/interface'
import { useAppDispatch, useAppSelector } from '@/core/store'
import { getPoints } from '@/core/store/catalog.store'
import { setUserLocation, setUserPosition, setUserRealPosition } from '@/core/store/ui.store'

export const useGeolocation = () => {
  //const [geolocationAllowed, setGeolocationAllowed] = useState(false)

  let geolocationAllowed = false

  const setEnabledGeolocation = () => {
    geolocationAllowed = true
  }

  const setDisabledGeolocation = () => {
    geolocationAllowed = false
  }

  const [useFallbackGeolocation, setFallbackGeolocation] = useState(false)

  const { locationManual, locale } = useAppSelector((store) => store.uiState)

  const dispatch = useAppDispatch()

  const { t, i18n } = useTranslation('root')

  const position_options = {
    enableHighAccuracy: true,
    timeout: 10000,
    maximumAge: 1000,
  }

  const isIOS =
    navigator.userAgent.match(/(iPod|iPhone|iPad)/) && navigator.userAgent.match(/AppleWebKit/)

  let lastLocation: Coordinates | null = null

  let watcher: number | undefined = undefined

  function initFakeGeo() {
    const { latitude, longitude, heading } = {
      latitude: 41.89021,
      longitude: 12.4923,
      heading: null,
    }

    // TODO: can we simulate a callback with position update?
    // const pos: GeolocationPosition = {}
    // dispatch(onPositionUpdate({ position: pos }))
    // //TODO: Stupid, I don't know how to access userLocation from ui.store in non-constant way inside callback
    lastLocation = { latitude, longitude }

    dispatch(setUserPosition({ latitude, longitude, heading }))

    //TODO: Which of these should go first? Shouldn't we first get the points and then update the position?
    dispatch(setUserLocation({ latitude, longitude }))

    //This, if successful, will cause App to re-render, which, in turn
    dispatch(getPoints({ latitude, longitude, locale: i18n.language as Language }))
    return lastLocation
  }

  const onPositionUpdate = useCallback(
    (position: GeolocationPosition) => {
      let { latitude, longitude } = position.coords

      dispatch(setUserRealPosition({ latitude, longitude, heading: 0 }))

      if (locationManual) return
      if (!geolocationAllowed) {
        //If geolocation data is coming, it must have been somehow allowed
        setEnabledGeolocation() //We just acqnowledge this fact A.N.
      }

      const { heading, accuracy, speed } = position.coords

      setFallbackGeolocation(!heading)

      //If we are far outside of Rome, force position to center of Rome
      //TODO: Make it a nicer solution
      if ((latitude > 42.05 || latitude < 41.72) && (longitude < 12.146 || longitude > 12.7777)) {
        latitude = 41.89021
        longitude = 12.4923
      }

      dispatch(setUserPosition({ latitude, longitude, heading }))

      //If the incoming geoposition is too close to userLocation -
      //just don't bother changing to prevent blinking and other triggers
      if (
        lastLocation &&
        Math.abs(lastLocation.latitude - latitude) < 0.0001 &&
        Math.abs(lastLocation.longitude - longitude) < 0.0001
      ) {
        return
      }

      //TODO: Stupid, I don't know how to access userLocation from ui.store in non-constant way inside callback
      lastLocation = { latitude, longitude }

      //TODO: Which of these should go first? Shouldn't we first get the points and then update the position?
      dispatch(setUserLocation({ latitude, longitude }))
      //Request points for the new location
      dispatch(getPoints({ latitude, longitude, locale: i18n.language as Language }))
    },
    [locationManual, geolocationAllowed, i18n.language],
  )

  //TODO: need to move this away from this module
  const onEnvironmentUpdate = useCallback(
    (position: GeolocationPosition) => {
      if (locationManual) return
      if (!geolocationAllowed) setEnabledGeolocation() //By the fact of callback it has been enabled

      const { latitude, longitude } = position.coords
      const { heading, accuracy, speed } = position.coords
    },
    [locationManual, geolocationAllowed, i18n.language],
  )

  const geoSuccess = useCallback((position: GeolocationPosition) => {
    //console.log('Geolocation allowed by user')
    setEnabledGeolocation()

    if (!watcher) {
      watcher = navigator.geolocation.watchPosition(
        onPositionUpdate,
        (error) => {
          setDisabledGeolocation()
        },
        position_options,
      )
      //console.log('useGeo::initGeo prompt watcher<=' + watcher)
    }
  }, [])

  const geoDenied = useCallback((err: GeolocationPositionError) => {
    //console.log(`useGeo::denied (${err.code}): ${err.message}`)
    setDisabledGeolocation()
    if (err.code == 1) {
      //USer denied geolocation explicitly!!!!
    }
    if (!lastLocation) {
      lastLocation = initFakeGeo()
    }
  }, [])

  //forced fetch location - currently done from the App rendering directly
  //to initialize geolocation
  const initGeo = useCallback(() => {
    //console.log('useGeo::initGeo()')
    if (locationManual) {
      //console.log('useGeo::initGeo::manual - returning')
      return
    }
    if (watcher) {
      //console.log('useGeo::initGeo::watcher exists - returning')
      return
    }

    const setupGeolocation = (permissionState: PermissionState) => {
      if (permissionState === 'granted') {
        setEnabledGeolocation()
        if (!watcher) {
          watcher = navigator.geolocation.watchPosition(
            onPositionUpdate,
            (error) => {
              setDisabledGeolocation()
            },
            position_options,
          )
          //console.log('useGeo::initGeo granted watcher<=' + watcher)
        }
      } else {
        // For both 'prompt' and 'denied' states, we show the loader for 3.5 seconds
        setTimeout(() => {
          if (permissionState === 'prompt') {
            navigator.geolocation.getCurrentPosition(geoSuccess, geoDenied, {
              timeout: 4000,
              maximumAge: 0,
            })
          } else if (permissionState === 'denied') {
            //console.log('useGeo::initGeo denied')
            if (!lastLocation) {
              lastLocation = initFakeGeo()
            }
          }
        }, 3500)
      }
    }

    try {
      navigator.permissions.query({ name: 'geolocation' }).then((result) => {
        //console.log('useGeo::initGeo:: permission query =>' + result.state)
        setupGeolocation(result.state)
      })
    } catch (error) {
      //console.log('useGeo::initGeo:: permission query failed, using fallback')
      // Fallback for older browsers or if permissions API fails
      setTimeout(() => {
        if (!lastLocation) {
          const { latitude, longitude } = { latitude: 41.89021, longitude: 12.4923 }
          try {
            if (!watcher) {
              watcher = navigator.geolocation.watchPosition(
                onPositionUpdate,
                (error) => {
                  setDisabledGeolocation()
                  lastLocation = { latitude, longitude }
                },
                position_options,
              )
            }
          } catch {
            setDisabledGeolocation()
            lastLocation = { latitude, longitude }
          }
        }
      }, 3500)
    }
  }, [locationManual])

  const deviceHeadingWatcher = throttle((e: any) => {
    let heading = null
    if (e.webkitCompassHeading || e.alpha) {
      heading = e.webkitCompassHeading || Math.abs(e.alpha - 360)
    }

    if (heading) {
      const newPos = { latitude: null, longitude: null, heading: heading }

      dispatch(setUserPosition(heading))
    }
  }, 600)

  // useEffect(() => {
  //   // no need to fetch, the watch will call the same imediately
  //   //initGeo()
  //   setUserLocation({ latitude: 41.89021, longitude: 12.4923 })
  //   if (geolocationAllowed) {
  //     navigator.geolocation.watchPosition(onPositionUpdate, null, position_options)
  //   }
  // }, [geolocationAllowed])

  // const startGeolocation = useCallback(() => {
  //   //will determine if location is allowed
  //   initGeo()

  //   // if (geolocationAllowed) {
  //   //   navigator.geolocation.watchPosition(onPositionUpdate, null, position_options)
  //   // } else {
  //   //   //TODO: dirty hack
  //   //   setUserLocation({ latitude: 41.89021, longitude: 12.4923 })
  //   // }
  // }, [geolocationAllowed])

  useEffect(() => {
    if (useFallbackGeolocation) {
      if (isIOS) {
        let errored = false

        // @ts-ignore
        if (typeof DeviceOrientationEvent['requestPermission'] === 'function') {
          // @ts-ignore
          DeviceOrientationEvent['requestPermission']()
            .then((permissionState: string) => {
              if (permissionState === 'granted') {
                window.addEventListener('deviceorientation', deviceHeadingWatcher, true)
              }
            })
            .catch(() => {
              errored = true
            })
        }

        if (errored) {
          try {
            window.addEventListener('deviceorientation', deviceHeadingWatcher, true)
          } catch {
            console.warn('Compass not supported')
          }
        }
      } else {
        window.addEventListener('deviceorientationabsolute', deviceHeadingWatcher, true)
      }
    }

    return () => {
      window.removeEventListener('deviceorientation', deviceHeadingWatcher, true)
      window.removeEventListener('deviceorientationabsolute', deviceHeadingWatcher, true)
    }
  }, [useFallbackGeolocation])

  return { initGeo: initGeo }
}
