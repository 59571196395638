import React from 'react'

export const Copyright = ({ active, onClose }: { active: boolean; onClose: () => void }) => {
  return (
    <div className={`sheet ${active ? 'active' : ''}`}>
      <button onClick={onClose} className="routes__close">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="34"
          height="24"
          viewBox="0 0 34 24"
          fill="none"
        >
          <path
            d="M26.8331 5L7.38867 19"
            stroke="#2B3537"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M7.38867 5L26.8331 19"
            stroke="#2B3537"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      </button>
      <h1 className="agr__title">Copyright</h1>
      <p>
        All audio and image content on this website is copyrighted and protected by intellectual
        property laws, except for images that are in the public domain or specifically attributed
        below. The following images are used with attribution to their respective creators:
      </p>
      <h3 className="agr__title">1. Wikimedia Users:</h3>
      <ul>
        <li>
          Sailko: Bocca della Verità, Pauline Chapel, Basilica di Santa Cecilia in Trastevere,
          Galleria d&apos;arte moderna di Roma Capitale, Saint Stephen of the Ethiopians
        </li>
        <li>Roundtheworld: Chiesa di Sant&apos;Urbano alla Caffarella</li>
        <li>Jean-Pol GRANDMONT: Aqua Claudia, Fontana dell Obelisco (Piazza del Popolo)</li>
        <li>Tessier~commonswiki: Museum and Crypt of the Capuchin Friars</li>
        <li>Tyrhénienne: Antica casa dei SS.MM. Giovanni e Paolo</li>
        <li>Rione I Monti: Basilica di Santa Prassede, Saint Peter in Chains</li>
        <li>JoJan: Palazzo di Propaganda Fide</li>
        <li>Dguendel: Fountain in Piazza Santa Maria in Trastevere</li>
        <li>
          Labicanense: Fontana dell&apos;Acqua Paola, Galleria Sciarra, Madonna dell&apos;Archetto,
          Chiesa di Santa Maria in Aquiro, Church of St. Louis of the French
        </li>
        <li>Lalupa: Teatro Salone Margherita</li>
        <li>Pilecka: Abbey of Saint Paul Outside the Walls</li>
        <li>Croberto68: Chiesa di San Saturnino</li>
        <li>Cyberuly: Santa Bibiana</li>
        <li>Jastrow: Lateran Palace</li>
        <li>
          Fiat 500e: St Ignatius, Palazzo del Grillo (Rome), Palazzo Doria Pamphilj, San Lorenzo in
          Damaso, Sant&apos;Agata dei Goti, Basilica di Sant&apos;Antonio da Padova
          all&apos;Esquilino, Santa Caterina a Magnanapoli, Santa Maria in Monterone, Chiesa dei
          Santi Celso e Giuliano, Chiesa dei Santi Domenico e Sisto
        </li>
        <li>PubblicUsername: Convento di San Pietro in Montorio, Villa di Quintili</li>
        <li>S. Agnese fuori le mura: Basilica di Sant&apos;Agnese Fuori le Mura</li>
        <li>Messapi: Chiesa di Sant&apos;Antonio Abate all&apos;Esquilino</li>
        <li>
          SonyGM: Chiesa di Sant&apos;Antonio in Campo Marzio, Monastero Agostiniano delle Suore di
          Santa Lucia in Selci, The Church of Domine Quo Vadis, Trinità dei Monti
        </li>
        <li>Felix Koenig: Santa Maria in Cosmedin</li>
        <li>RealRome: Chiesa dei Santi Nereo e Achilleo</li>
        <li>IlMarcheseDelGrillo: Torre dei Conti</li>
        <li>Jean-Christophe BENOIST: Temple of Aesculapius</li>
        <li>Arqpalazzo: Chiesa di Sant&apos;Atanasio a Via Tiburtina</li>
      </ul>

      <h3 className="agr__title">2. Flickr Users:</h3>
      <ul>
        <li>Anthony M.: Museo Storico della Liberazione, Temple of Vespasian and Titus</li>
        <li>Jean-Pierre Dalbéra: Centrale Montemartini, Planetario e Museo Astronomico</li>
        <li>dalbera: Palazzo della Civiltà Italiana</li>
        <li>xiquinhosilva: Museo Gregoriano Etrusco</li>
        <li>Giorgio Minguzzi: Palazzo Magistrale</li>
      </ul>

      <h3 className="agr__title">3. Other Contributors:</h3>
      <ul>
        <li>gaspa: Chiesa di Santa Maria della Pace</li>
        <li>sonofgroucho: Piazza di San Bartolomeo all&apos;Isola</li>
        <li>
          DellaGherardesca: Palazzo Chigi, Palazzo Montecitorio, Basilica dei Santi Ambrogio e Carlo
          al Corso, San Giacomo in Augusta, San Giovanni Battista Decollato, Basilica di San Lorenzo
          in Lucina, Sant&apos;Eligio dei Ferrari
        </li>
        <li>antmoose: Casa di Livia</li>
        <li>
          Mister No: Palazzo Cimarra, Palazzo Koch, Fontana di Esculapio (Fontana del Fiocco) a
          Villa Borghese
        </li>
        <li>WolfgangM: Piazza del Popolo</li>
        <li>MarianOne: Museo Carlo Bilotti Aranciera di Villa Borghese</li>
        <li>Van Loon: Parco museo ferroviario Met.Ro Roma Porta San Paolo</li>
        <li>Ardeatino: Chiesa di San Cesareo de Appia</li>
        <li>Robert Lowe: Ara di Saturno</li>
        <li>RAI: Chiesa di Santa Maria del Priorato</li>
      </ul>

      <h3 className="agr__title">4. Unknown Authors:</h3>
      <ul>
        <li>Giardini del Quirinale</li>
        <li>Baths of Diocletian</li>
        <li>Catacombe di San Sebastiano</li>
        <li>San Paolo</li>
        <li>Santi Cosma e Damiano</li>
        <li>Riserva Naturale di Monte Mario</li>
        <li>Tempio di Apollo</li>
        <li>Villa Giulia</li>
      </ul>

      <h3 className="agr__title">5. Official Source:</h3>
      <ul>
        <li>vatican.va: Piazza Santa Maria consolatrice</li>
      </ul>
    </div>
  )
}
