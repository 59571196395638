//Utility code for tracking hooks
let hookCount = 0
let hookList: string[] = []

export function useHookTracker(hookName: string) {
  hookCount++
  hookList.push(hookName)
}

export function getHookCount() {
  return hookCount
}

export function getHookList() {
  return hookList
}

export function resetHookTracker() {
  hookCount = 0
  hookList = []
}
