import Cookies from 'js-cookie'
import { FetchError, FetchOptions, ofetch } from 'ofetch'

interface IRequestOptions {
  method?: string
  body?: { [key: string]: any }
  headers?: { [key: string]: string }
  params?: { [key: string]: string }
}

interface IPostOptions {
  method?: string
  body?: string
  headers?: { [key: string]: string }
  params?: { [key: string]: string }
}

interface IError {
  status: number
  message: string
  raw: any
}

export const serverapi = async (
  url: string,
  { method = 'GET', body, params, headers }: IRequestOptions,
) => {
  try {
    const requestOptions = {
      method,
      headers: {
        'Content-Type': 'application/json' as string,
      },
      body,
      params,
    } as FetchOptions

    if (headers) {
      requestOptions.headers = {
        ...requestOptions.headers,
        ...headers,
      }
    }

    const i18nextLng = localStorage.getItem('i18nextLng')

    if (i18nextLng) {
      requestOptions.params = {
        ...requestOptions.params,
        lang: i18nextLng,
      }
    }

    let requestUrl = `${process.env.REACT_APP_API_URL}${url}`

    if (url.startsWith('http')) {
      requestUrl = url
    }

    const data = await ofetch(requestUrl, requestOptions)
    //console.log(`👌 fetch ${url} ${JSON.stringify(requestOptions.params)}`, data)

    return { data, error: null }
  } catch (err: any) {
    let errMessage = err?.data?.message || ''

    if (!errMessage) {
      switch (err?.status) {
        case 500:
          errMessage = 'Server error'
          break
        case 403:
          errMessage = 'Auth error'
          break
      }
    }

    const error: IError = { status: err?.status || 500, message: errMessage, raw: err }

    console.log('❌ Request Error', error)

    return { data: null, error }
  }
}

export const feedback_api = async (
  userid: string,
  stars: number,
  { method = 'POST', body, params, headers }: IPostOptions,
) => {
  try {
    const requestOptions = {
      method,
      headers: {
        'Content-Type': 'text/plain; charset=utf-8' as string,
      },
      body,
      params,
    } as FetchOptions

    if (headers) {
      requestOptions.headers = {
        ...requestOptions.headers,
        ...headers,
      }
    }

    const i18nextLng = localStorage.getItem('i18nextLng')

    if (i18nextLng) {
      requestOptions.params = {
        ...requestOptions.params,
        lang: i18nextLng,
      }
    }

    //TODO: needs to be a parameter
    const REACT_FEEDBACK_API_URL =
      'https://orkeywx3ml.execute-api.eu-central-1.amazonaws.com/prod/feedback'
    const requestUrl = `${REACT_FEEDBACK_API_URL}/${userid}/${stars}` //`${process.env.REACT_FEEDBACK_API_URL}/${userid}/${stars}`

    const data = await ofetch(requestUrl, requestOptions)
    console.log(`👌 feedback ${requestUrl} ${JSON.stringify(requestOptions.params)}`, data)

    return { data, error: null }
  } catch (err: any) {
    let errMessage = err?.data?.message || ''

    if (!errMessage) {
      switch (err?.status) {
        case 500:
          errMessage = 'Server error'
          break
        case 403:
          errMessage = 'Auth error'
          break
      }
    }

    const error: IError = { status: err?.status || 500, message: errMessage, raw: err }

    console.log('❌ Request Error', error)

    return { data: null, error }
  }
}
