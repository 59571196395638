import { Excursion } from '@/core/interface/Excursion'
import { serverapi } from '@/core/serverapi'

export const getExcursions = async (locale: string) => {
  const { data } = (await serverapi(`${locale}/excursion/excursion.json`, {})) as {
    data: Excursion[]
  }

  return data
}
