import { DirectionsRenderer } from '@react-google-maps/api'
import { useEffect, useState } from 'react'

function MapDirectionsRenderer({ places, travelMode }: { places: any[]; travelMode: any }) {
  const [directions, setDirections] = useState<any>(null)
  const [distanceMatrix, setDistanceMatrix] = useState<any>(null)

  useEffect(() => {
    const fetchDirectionsAndDistance = async () => {
      if (!places || places.length < 2) return

      const directionsService = new google.maps.DirectionsService()
      const distanceMatrixService = new google.maps.DistanceMatrixService()

      // Create new waypoints array
      const waypoints = places.slice(1, -1).map((place) => ({
        location: new google.maps.LatLng(place.latitude, place.longitude),
        stopover: true,
      }))

      const origin = new google.maps.LatLng(places[0].latitude, places[0].longitude)
      const destination = new google.maps.LatLng(
        places[places.length - 1].latitude,
        places[places.length - 1].longitude,
      )

      const directionsRequest = {
        origin: origin,
        destination: destination,
        travelMode: travelMode,
        waypoints: waypoints,
        optimizeWaypoints: false,
      }

      try {
        // Get directions
        const directionsResult = await new Promise((resolve, reject) => {
          directionsService.route(directionsRequest, (result, status) => {
            if (status === google.maps.DirectionsStatus.OK) {
              resolve(result)
            } else {
              reject(status)
            }
          })
        })
        setDirections(directionsResult)

        // Get distance matrix
        const distanceMatrixResult = await distanceMatrixService.getDistanceMatrix({
          origins: [origin],
          destinations: [...waypoints.map((wp) => wp.location), destination],
          travelMode: travelMode,
          avoidHighways: true,
          avoidTolls: true,
        })
        setDistanceMatrix(distanceMatrixResult)
      } catch (error) {
        console.error('Error fetching directions or distance:', error)
      }
    }

    fetchDirectionsAndDistance()
  }, [places, travelMode])

  return (
    directions && (
      <DirectionsRenderer
        directions={directions}
        options={{
          preserveViewport: false,
          suppressMarkers: false,
          markerOptions: {
            visible: false,
          },
        }}
      />
    )
  )
}

export default MapDirectionsRenderer
