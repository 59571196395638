import cns from 'classnames'
import { useEffect, useRef, useState } from 'react'

interface ILoaderProps {
  theme?: 'inline' | 'page' | 'overlay'
  active?: boolean
  threshold?: number
  children?: React.ReactElement[] | React.ReactElement
}

export const Loader: React.FC<ILoaderProps> = ({ active, threshold = 4000 }) => {
  const [loadingState, setLoadingState] = useState<boolean>(false)
  const timeLoading = useRef<NodeJS.Timeout | null>(null)

  useEffect(() => {
    if (active) {
      timeLoading.current = setTimeout(() => {
        setLoadingState(true)
      }, threshold)
    } else {
      if (timeLoading.current) {
        clearTimeout(timeLoading.current)
      }
      setLoadingState(false)
    }

    return () => {
      if (timeLoading.current) {
        clearTimeout(timeLoading.current)
      }
    }
  }, [active, threshold])

  return (
    <div className={cns('loading-block')}>
      <p className={'loader-text'}>
        This is a geolocating app, It won’t function unless you allow geolocation - here in browser
        and in your system settings.
      </p>
      <div className="loading-block__pic">
        <div className="loader">
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
        </div>
      </div>
    </div>
  )
}
