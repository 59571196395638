import { ExcursionCategory } from '@/core/interface/ExcursionCategory'
import { serverapi } from '@/core/serverapi'

export const getCategories = async (locale: string) => {
  const { data } = (await serverapi(`${locale}/excursion/categories.json`, {})) as {
    data: ExcursionCategory[]
  }

  return data
}
