import React from 'react'

export const UserAgreement = ({ active, onClose }: { active: boolean; onClose: () => void }) => {
  return (
    <div className={`sheet ${active ? 'active' : ''}`}>
      <button onClick={onClose} className="routes__close">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="34"
          height="24"
          viewBox="0 0 34 24"
          fill="none"
        >
          <path
            d="M26.8331 5L7.38867 19"
            stroke="#2B3537"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M7.38867 5L26.8331 19"
            stroke="#2B3537"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      </button>
      <h3 className="agr__title">USER AGREEMENT</h3>
      <div className="agr__content">
        <h4>END-USER LICENSE AGREEMENT (EULA) FOR WORLD MUSEUM APPLICATION</h4>

        <p>
          This End-User License Agreement (&quot;Agreement&quot;) is a legal agreement between you
          (&quot;User&quot;) and MAIT (&quot;Company&quot;), for the use of the World Museum web
          application (&quot;Application&quot;).
        </p>

        <h5>1. ACCEPTANCE OF TERMS</h5>
        <p>
          By accessing or using the Application, you agree to be bound by the terms and conditions
          of this Agreement. If you do not agree to these terms, please do not use the Application.
        </p>

        <h5>2. DESCRIPTION OF SERVICE</h5>
        <p>
          The Application provides an audio guide to Rome using generated content. The generated
          content is based on publicly available information and aims to offer informative and
          entertaining insights about various places of interest.
        </p>

        <h5>3. USE OF APPLICATION</h5>
        <p>3.1 The Application is not age-restricted and can be used by individuals of any age.</p>
        <p>
          3.2 The Application provides several audio guides for free for testing purposes. After the
          initial free period, payment is required to access the full content of the Application.
        </p>
        <p>
          3.3 Payment provides one year of access to the Application&apos;s full content from the
          date of purchase.
        </p>
        <p>
          3.4 Automated extraction of content from the Application is strictly prohibited. The
          Application is intended for reasonable personal use only.
        </p>

        <h5>4. INTELLECTUAL PROPERTY</h5>
        <p>
          4.1 The audio content, although mostly AI-generated, is the intellectual property of the
          Company. Some images are also the property of the Company.
        </p>
        <p>
          4.2 A significant portion of the content, including some images and information, is
          sourced from third parties under various licenses, including public domain, Creative
          Commons (CC0, CC BY), Unsplash, and Wikipedia. The Company does not claim ownership of
          this third-party content.
        </p>
        <p>
          4.3 Users are granted a limited, non-exclusive, non-transferable license to use all
          content within the Application for personal, non-commercial purposes.
        </p>
        <p>
          4.4 For a detailed list of content sources and attributions, please refer to the Copyright
          section in the Application&apos;s main menu.
        </p>

        <h5>5. DATA COLLECTION AND PRIVACY</h5>
        <p>
          5.1 The Application uses cookies and local storage to remember the state of the
          application in the user&apos;s web browser.
        </p>
        <p>5.2 Anonymized usage data is collected through Google Analytics.</p>
        <p>
          5.3 In the future, user accounts may be created, with account data stored on Amazon AWS.
        </p>

        <h5>6. DISCLAIMER OF WARRANTIES</h5>
        <p>
          6.1 The Application and its content are provided &quot;as is&quot; without warranty of any
          kind, either express or implied.
        </p>
        <p>
          6.2 The Company does not guarantee the accuracy, completeness, or usefulness of the
          content, especially given that it is AI-generated and may contain inaccuracies.
        </p>
        <p>
          6.3 Users are encouraged to use the feedback form within the Application to report any
          inaccuracies or issues.
        </p>

        <h5>7. LIMITATION OF LIABILITY</h5>
        <p>
          The Company shall not be liable for any direct, indirect, incidental, special,
          consequential or exemplary damages resulting from the use or inability to use the
          Application or its content.
        </p>

        <h5>8. REFUNDS AND CANCELLATIONS</h5>
        <p>
          8.1 Users may request a refund within 14 days of purchase if they are unsatisfied with the
          Application.
        </p>
        <p>
          8.2 Refunds will be processed at the Company&apos;s discretion and may be denied in cases
          of excessive use or abuse of the refund policy.
        </p>

        <h5>9. MODIFICATIONS TO SERVICE AND TERMS</h5>
        <p>
          The Company reserves the right to modify or discontinue, temporarily or permanently, the
          Application or any features or portions thereof without prior notice. The Company may also
          update this Agreement from time to time.
        </p>

        <h5>10. GOVERNING LAW</h5>
        <p>
          This Agreement shall be governed by and construed in accordance with the laws of Italy,
          without regard to its conflict of law principles.
        </p>

        <h5>11. TERMINATION</h5>
        <p>
          The Company reserves the right to terminate or suspend access to the Application, in whole
          or in part, at its sole discretion, without prior notice or liability.
        </p>

        <h5>12. CONTACT INFORMATION</h5>
        <p>For any questions concerning this Agreement or the Application, please contact:</p>
        <p>
          MAIT
          <br />
          Via Quadroni 19, 00189 Roma, Italy
          <br />
          Phone: +39 334 204 55 25
          <br />
          Email: maitsrls@gmail.com
        </p>

        <h5>13. ACKNOWLEDGMENTS</h5>
        <p>
          The Company would like to acknowledge and thank all the contributors and sources of
          content used in this Application. A comprehensive list of attributions can be found in the
          Copyright section of the Application&apos;s main menu.
        </p>

        <p>
          By using the World Museum application, you acknowledge that you have read, understood, and
          agree to be bound by the terms and conditions of this End-User License Agreement.
        </p>

        <p>Last updated: {new Date().toLocaleDateString()}</p>
      </div>
    </div>
  )
}
