//Wrapper around Sentry error boundary to count hooks
import * as Sentry from '@sentry/react'
import React from 'react'

import { getHookCount, getHookList, resetHookTracker } from '../core/hooks/useHookTracker'

interface ErrorBoundaryProps {
  children: React.ReactNode
  boundaryId: string
}

class SentryErrorBoundary extends React.Component<ErrorBoundaryProps> {
  componentDidCatch(error: Error, errorInfo: React.ErrorInfo) {
    Sentry.withScope((scope) => {
      scope.setExtra('boundaryId', this.props.boundaryId)
      scope.setExtra('hookCount', getHookCount())
      scope.setExtra('hookList', getHookList())
      Sentry.captureException(error)
    })
  }

  render() {
    return (
      <Sentry.ErrorBoundary
        fallback={({ error }) => {
          resetHookTracker() // Reset for next render
          return <div>An error has occurred, please reload the page.</div>
        }}
      >
        {this.props.children}
      </Sentry.ErrorBoundary>
    )
  }
}

export default SentryErrorBoundary
