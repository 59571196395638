import { Coordinates, Language } from '@/core/interface'
import { InterestPoint } from '@/core/interface/Catalog'
import { serverapi } from '@/core/serverapi'
import { buildParams } from '@/core/utils/api'

export interface IGetPlaces extends Coordinates {
  locale: Language
  type: string
}

export const getClosestObjects = async ({ latitude, longitude, locale, type }: IGetPlaces) => {
  const lon = Math.round((longitude + Number.EPSILON) * 100) / 100
  const lat = Math.round((latitude + Number.EPSILON) * 100) / 100

  const params = buildParams({
    // apikey: process.env.REACT_APP_OPEN_TRIP_MAP,
    lon_min: lon - 0.05,
    lon_max: lon + 0.05,
    lat_min: lat - 0.05,
    lat_max: lat + 0.05,
  })

  const { data } = (await serverapi(`${locale}/${type}/points.json`, {
    params: params,
  })) as { data: InterestPoint[] }

  return data
}
