import React, { useState } from 'react'
import ReactGA from 'react-ga4'
import { useTranslation } from 'react-i18next'

import { AudioFile } from '@/core/interface/Default'
import { useAppDispatch, useAppSelector } from '@/core/store'
import { addPlayedAudio } from '@/core/store/catalog.store'

interface AudioPlayerProps {
  id: string
  files: AudioFile[]
  type: number //there may be several audios assigned to the same object
  autoPlaying: boolean
  excursion: string | undefined
  location?: {
    latitude: number
    longitude: number
  }
}

const AudioPlayer: React.FC<AudioPlayerProps> = ({
  id,
  files,
  type,
  autoPlaying,
  excursion,
  location,
}) => {
  const [isPlaying, setIsPlaying] = useState(false)
  const audioRef = React.useRef<HTMLAudioElement | null>(null)

  const userRealPosition = useAppSelector((store) => store.uiState.userRealPosition)

  const { playedAudioIds } = useAppSelector((store) => store.catalogStore)
  const dispatch = useAppDispatch()

  const calculateDistance = () => {
    if (!location || !userRealPosition) return null

    const R = 6371e3 // Earth's radius in meters
    const φ1 = (userRealPosition.latitude * Math.PI) / 180
    const φ2 = (location.latitude * Math.PI) / 180
    const Δφ = ((location.latitude - userRealPosition.latitude) * Math.PI) / 180
    const Δλ = ((location.longitude - userRealPosition.longitude) * Math.PI) / 180

    const a =
      Math.sin(Δφ / 2) * Math.sin(Δφ / 2) +
      Math.cos(φ1) * Math.cos(φ2) * Math.sin(Δλ / 2) * Math.sin(Δλ / 2)
    const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a))

    const distance = R * c
    return Math.round(distance)
  }

  const handlePlay = () => {
    Array.from(document.querySelectorAll('audio')).forEach((audio) => {
      if (id !== audio.getAttribute('data-id')) {
        if (!audio.paused) audio.pause()
      }
    })

    {
      ReactGA.event({
        category: 'play',
        action: 'AudioPlayed',
        label: id,
        value: playedAudioIds.length + 1,
      })
    }

    //will trigger GA event by changing the playedAudioIds
    dispatch(addPlayedAudio(id))
  }

  const handlePause = () => {
    if (audioRef.current) {
      setIsPlaying(false)
      if (!audioRef.current.paused) audioRef.current.pause()
    }
    ReactGA.event({
      category: 'play',
      action: 'AudioPaused',
      label: id,
    })
  }

  const handleEnded = () => {
    if (audioRef.current) {
      setIsPlaying(false)
      if (!audioRef.current.paused) audioRef.current.pause()
    }
    ReactGA.event({
      category: 'play',
      action: 'AudioEnded',
      label: id,
    })
  }

  const audioFile = files.find((x) => x.type === type)

  if (!audioFile) {
    return <div>No audio file .</div>
  }

  if (autoPlaying) {
    return (
      <div>
        <audio
          data-id={id}
          preload={'none'}
          ref={audioRef}
          controls={true}
          autoPlay={true}
          controlsList={'nofullscreen nodownload '}
          src={audioFile.url}
          onPause={handlePause}
          onPlay={handlePlay}
          onEnded={handleEnded}
        />
      </div>
    )
  } else {
    return (
      <div>
        <audio
          data-id={id}
          preload={'none'}
          ref={audioRef}
          controls={true}
          controlsList={'nofullscreen nodownload '}
          src={audioFile.url}
          onPause={handlePause}
          onPlay={handlePlay}
          onEnded={handleEnded}
        />
      </div>
    )
  }
}

export default AudioPlayer
