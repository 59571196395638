import React from 'react'
import ReactGA from 'react-ga4'
import { Link } from 'react-router-dom'

import { landing_data_tours } from './data'

type CardTourProps = {
  img: string
  name: string
  description: string
  id: string
  categoryId: number
  link: string
  lat?: number
  lon?: number
  length?: string
  time?: string
  price?: number
  rating?: string
  isLeft: boolean
}

export const ListedItem: React.FC<CardTourProps> = ({
  img,
  name,
  description,
  id,
  categoryId,
  link,
  lat,
  lon,
  length,
  time,
  price,
  rating,
  isLeft,
}) => {
  const handleClick = () => {
    // Scroll to the top of the page
    window.scrollTo(0, 0)
    const action = 'ClickedCardTour_' + id
    ReactGA.event({
      category: 'click',
      action: action,
    })
  }

  return (
    <div className={'landing__item_tour'}>
      <Link
        hrefLang="en"
        onClick={handleClick}
        className={!isLeft ? 'landing__item_tour-right' : 'landing__item_tour-left'}
        to={link}
        state={{
          excursionId: id,
          categoryId: categoryId,
          lat: lat,
          lon: lon,
        }}
      >
        <h3>{name}</h3>
        <div className="landing__item_tour-text">
          <p>{description}</p>
        </div>
        <div className="landing__item_tour-img">
          <img src={img} alt={name} />
          <div className="tour-info">
            <div className="info-section time">
              <span className="material-icons icon">schedule</span>
              <span className="value">{time}h</span>
            </div>
            <div className="separator" />
            <div className="info-section length">
              <span className="material-icons icon">straighten</span>
              <span className="value">{length}km</span>
            </div>
            <div className="separator" />
            <div className="info-section price">
              <span className="material-icons icon euro">euro</span>
              <span className="value">{/*0,00 price*/}</span>
            </div>
            <div className="separator" />
            <div className="info-section rating">
              <span className="material-icons icon rating">star</span>
              <span className="value">{rating}</span>
            </div>
          </div>
        </div>
      </Link>
    </div>
  )
}

interface CardInterface {
  selectedCategory: any
}

export const CardTour: React.FC<CardInterface> = ({ selectedCategory }) => {
  const filteredData = landing_data_tours.filter(
    (obj) =>
      selectedCategory === null ||
      (Array.isArray(selectedCategory) && selectedCategory.includes(obj.categoryId)),
  )
  const sortedData = [...filteredData]

  return (
    <div className="landing__card">
      <div className="container">
        <div className="card_wrapper">
          {sortedData.map((obj, i) => (
            <ListedItem key={i} {...obj} isLeft={i % 2 === 0} />
          ))}
        </div>
      </div>
    </div>
  )
}
