import { useState } from 'react'
import ReactGA from 'react-ga4'

import { ObjectSelection } from '@/core/interface/Catalog'
import { useAppDispatch, useAppSelector } from '@/core/store'
import {
  DEFAULT_CATEGORIES,
  setFilterChanged,
  setSelectedCategories,
} from '@/core/store/catalog.store'
interface CategoryFilterProps {
  onApply: () => void
}

const CategoryFilter: React.FC<CategoryFilterProps> = ({ onApply }) => {
  const { selectedCategories } = useAppSelector((store) => store.catalogStore)

  const dispatch = useAppDispatch()

  const [selectedNow, setSelectedNow] = useState<string[]>(selectedCategories.included)
  const names = new Map([
    // ['10', 'Top attractions'],
    // ['7', 'Worth visiting'],
    // ['3', 'Take a look if walking around'],
    // ['5', 'May be interesting'],
    ['B', 'Buildings'],
    ['M', 'Museums'],
    ['C', 'Churches'],
    ['P', 'Plazas'],
    ['S', 'Monuments'],
    ['O', 'Places'],
    ['B-M', 'Museums&interesting buildings'],
    ['Ponte', 'Bridges'],
    ['B-P', '_'],
    ['Area', 'Areas'],
    ['Other', 'Other'],
    ['Street', 'Streets'],
    ['S-P', 'Monuments and areas'],
    ['X', 'Unknowns'],
  ])
  //const categoriesToUse = ['10', '7', '5', '3']
  const objectsToUse = ['B', 'M', 'C', 'S', 'P', 'Ponte', 'Other']

  const getCategoryName = (cat: string) => {
    //TODO: i18n
    const n = names.get(cat)
    return n
  }

  const applyCategoryChange = () => {
    dispatch(
      setSelectedCategories({ category: selectedCategories.category, included: selectedNow }),
    )

    //check if current state is different from default
    const sortedSelected = [...selectedNow].sort()
    const sortedDefault = [...DEFAULT_CATEGORIES].sort()
    const isChanged = JSON.stringify(sortedSelected) !== JSON.stringify(sortedDefault)

    dispatch(setFilterChanged(isChanged))

    onApply() // Call the onApply function when categories are applied
    ReactGA.event({
      category: 'click',
      action: 'CategoryApply',
      label: selectedNow.join(','),
    })
  }

  const handleCategoryChange = (category: string) => {
    if (selectedNow?.includes(category)) {
      setSelectedNow(selectedNow?.filter((cat) => cat !== category))
      ReactGA.event({
        category: 'click',
        action: 'CategorySelect',
        label: selectedNow?.filter((cat) => cat !== category).join(','),
      })
    } else {
      setSelectedNow(selectedNow ? [...selectedNow, category] : [category])
      ReactGA.event({
        category: 'click',
        action: 'CategorySelect',
        label: (selectedNow ? [...selectedNow, category] : [category]).join(','),
      })
    }
  }

  return (
    <div id={'noClose'} onClick={(e) => e.stopPropagation()}>
      {[...objectsToUse].map((category) => (
        <div id={'noClose'} key={category} className={'checkBox'}>
          <span id={'noClose'} className="box" onClick={() => handleCategoryChange(category)}>
            {selectedNow?.includes(category) && (
              <svg
                id={'noClose'}
                xmlns="http://www.w3.org/2000/svg"
                width="20"
                height="16"
                viewBox="0 0 20 16"
                fill="none"
              >
                <path
                  id={'noClose'}
                  d="M7.70711 10.5858L3.76777 6.64645L3.41421 6.29289L3.06066 6.64645L1.64645 8.06066L1.29289 8.41421L1.64645 8.76777L7.35355 14.4749L7.70711 14.8284L8.06066 14.4749L18.7678 3.76777L19.1213 3.41421L18.7678 3.06066L17.3536 1.64645L17 1.29289L16.6464 1.64645L7.70711 10.5858Z"
                  fill="#FD8108"
                  stroke="#FD8108"
                />
              </svg>
            )}
          </span>
          <p id={'noClose'}>{getCategoryName(category)}</p>
        </div>
      ))}
      <button id={'noClose'} className="apply" onClick={applyCategoryChange}>
        APPLY
      </button>
    </div>
  )
}

export default CategoryFilter
