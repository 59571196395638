import Cookies from 'js-cookie'

import { feedback_api } from '@/core/serverapi'

interface IPostFeedback {
  comment: string
  email: string
  rating: number
  latitude: number | null
  longitude: number | null
}

export const postFeedback = async ({
  comment,
  rating,
  email,
  latitude,
  longitude,
}: IPostFeedback) => {
  const userid = Cookies.get('userID') || '0'

  const location = latitude && longitude ? `${latitude},${longitude}` : '?,?'

  const params = {
    email: email,
    location: location,
  }

  try {
    await feedback_api(userid, rating, {
      body: comment,
      params: params,
    })
  } catch (error) {
    console.error('Error fetching data:', error)
    return false
  }

  return true
}
