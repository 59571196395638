export function createNumberedIcon(number: number, isCurrentStep = false): string {
  const color = isCurrentStep ? '#FF0000' : '#FFA500'
  const svg = `
    <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32">
      <defs>
        <filter id="shadow" x="-20%" y="-20%" width="140%" height="140%">
          <feOffset result="offOut" in="SourceAlpha" dx="1" dy="1" />
          <feGaussianBlur result="blurOut" in="offOut" stdDeviation="1" />
          <feBlend in="SourceGraphic" in2="blurOut" mode="normal" />
        </filter>
      </defs>
      <circle cx="16" cy="16" r="14" fill="${color}" stroke="white" stroke-width="2" filter="url(#shadow)" />
      <text x="16" y="22" font-family="Arial, sans-serif" font-size="14" font-weight="bold" text-anchor="middle" fill="white">
        ${number}
      </text>
    </svg>
  `

  return `data:image/svg+xml;charset=utf-8,${encodeURIComponent(svg)}`
}

export const containerStyle = {
  width: '100%',
  height: '100%',
}

export const mapOptions = {
  mapTypeId: 'roadmap',
  disableDefaultUI: true, //disable streetview, etc.
  zoomControl: true,
  rotateControl: true,
  zoomControlOptions: {
    position: 8, //google.maps.ControlPosition.RIGHT_CENTER
  },
  preserveViewport: true,
  gestureHandling: 'greedy', //one finger movements
  styles: [
    { featureType: 'poi', elementType: 'labels', stylers: [{ visibility: 'off' }] },
    {
      featureType: 'transit.station',
      elementType: 'labels.icon',
      stylers: [{ visibility: 'on' }, { color: '#80cccc' }],
    },
    { featureType: 'all', elementType: 'labels.text', stylers: [{ color: '#878787' }] },
    { featureType: 'all', elementType: 'labels.text.stroke', stylers: [{ visibility: 'off' }] },
    { featureType: 'landscape', elementType: 'all', stylers: [{ color: '#f9f5ed' }] },
    { featureType: 'road.highway', elementType: 'all', stylers: [{ color: '#f5f5f5' }] },
    {
      featureType: 'road.highway',
      elementType: 'geometry.stroke',
      stylers: [{ color: '#c9c9c9' }],
    },
    { featureType: 'water', elementType: 'all', stylers: [{ color: '#aee0f4' }] },
  ],
}

export const createCenterControl = ({ onClickHandler }: { onClickHandler: () => void }) => {
  const controlButton = document.createElement('button')

  // Set CSS for the control.
  controlButton.style.backgroundColor = '#26a69a'
  controlButton.style.border = '2px solid #26a69a'
  controlButton.style.borderRadius = '3px'
  controlButton.style.boxShadow = '0 2px 6px rgba(0,0,0,.3)'
  controlButton.style.color = 'rgb(255,255,255)'
  controlButton.style.cursor = 'pointer'
  controlButton.style.fontFamily = 'Roboto,Arial,sans-serif'
  controlButton.style.fontSize = '14px'
  controlButton.style.lineHeight = '32px'
  // controlButton.style.margin = '8px 10px 22px'
  controlButton.style.margin = '15px'
  controlButton.style.padding = '0 5px'
  controlButton.style.textAlign = 'center'

  controlButton.textContent = 'Where am I?'
  controlButton.title = 'Click to recenter the map'
  controlButton.type = 'button'

  // Setup the click event listeners: simply set the map to Center.
  controlButton.addEventListener('click', onClickHandler)

  return controlButton
}

export const circleOptions = {
  strokeColor: '#FF0000',
  strokeOpacity: 0.5,
  strokeWeight: 2,
  fillColor: '#FF0000',
  fillOpacity: 0.1,
  clickable: false,
  draggable: false,
  editable: false,
  visible: true,
  radius: 20,
}

export const icon_types = new Map([
  ['M', '/img/new/muse.png'],
  ['B', '/img/new/arch.png'],
  ['C', '/img/new/chur.png'],
  ['P', '/img/new/plac.png'],
  ['Street', '/img/new/plac.png'],
  ['Ponte', '/img/new/bidg.png'],
  ['B-P', '/img/new/plac.png'],
  ['B-M', '/img/new/muse.png'],
  ['Area', '/img/new/plac.png'],
  ['S', '/img/new/scul.png'],
  ['B', '/img/new/arch.png'],
  ['O', '/img/new/star.png'],
])

export const icon_sizes = new Map([
  ['10', 48],
  ['7', 32],
  ['5', 24],
  ['3', 18],
])

function findNearestNonNullIndex(array: any[], idx: number) {
  // Iterate from the given index towards the beginning of the array
  for (let i = idx; i >= 0; i--) {
    if (array[i] !== null && array[i] !== undefined) {
      return i
    }
  }

  // If no non-null value is found, return null
  return null
}

export const icon_sizes_x = new Map([
  [
    1,
    new Map([
      ['10', 18],
      ['7', 18],
      ['5', 18],
      ['3', 18],
    ]),
  ],
  [
    2,
    new Map([
      ['10', 18],
      ['7', 18],
      ['5', 18],
      ['3', 18],
    ]),
  ],
  [
    3,
    new Map([
      ['10', 18],
      ['7', 18],
      ['5', 18],
      ['3', 18],
    ]),
  ],
  [
    4,
    new Map([
      ['10', 18],
      ['7', 18],
      ['5', 18],
      ['3', 18],
    ]),
  ],
  [
    5,
    new Map([
      ['10', 18],
      ['7', 18],
      ['5', 18],
      ['3', 18],
    ]),
  ],
  [
    6,
    new Map([
      ['10', 18],
      ['7', 18],
      ['5', 18],
      ['3', 18],
    ]),
  ],
  [
    7,
    new Map([
      ['10', 18],
      ['7', 18],
      ['5', 18],
      ['3', 18],
    ]),
  ],
  [
    8,
    new Map([
      ['10', 18],
      ['7', 18],
      ['5', 18],
      ['3', 18],
    ]),
  ],
  [
    9,
    new Map([
      ['10', 18],
      ['7', 18],
      ['5', 18],
      ['3', 18],
    ]),
  ],
  [
    10,
    new Map([
      ['10', 18],
      ['7', 18],
      ['5', 18],
      ['3', 18],
    ]),
  ],
  [
    11,
    new Map([
      ['10', 48],
      ['7', 18],
      ['5', 18],
      ['3', 18],
    ]),
  ],
  [
    12,
    new Map([
      ['10', 48],
      ['7', 18],
      ['5', 18],
      ['3', 18],
    ]),
  ],
  [
    13,
    new Map([
      ['10', 48],
      ['7', 32],
      ['5', 24],
      ['3', 18],
    ]),
  ],
  [
    14,
    new Map([
      ['10', 48],
      ['7', 32],
      ['5', 24],
      ['3', 18],
    ]),
  ],
  [
    15,
    new Map([
      ['10', 48],
      ['7', 32],
      ['5', 24],
      ['3', 18],
    ]),
  ],
  [
    16,
    new Map([
      ['10', 48],
      ['7', 48],
      ['5', 48],
      ['3', 48],
    ]),
  ],
  [
    17,
    new Map([
      ['10', 48],
      ['7', 48],
      ['5', 48],
      ['3', 48],
    ]),
  ],
  [
    18,
    new Map([
      ['10', 48],
      ['7', 48],
      ['5', 48],
      ['3', 48],
    ]),
  ],
  [
    19,
    new Map([
      ['10', 48],
      ['7', 48],
      ['5', 48],
      ['3', 48],
    ]),
  ],
  [
    20,
    new Map([
      ['10', 48],
      ['7', 48],
      ['5', 48],
      ['3', 48],
    ]),
  ],
])
