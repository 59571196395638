import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'

import { getClosestObjects, IGetPlaces } from '@/api/catalog.api'
import { getExcursions } from '@/api/excursion.api'
import { getCategories } from '@/api/excursions-categories.api'
import { catalogTypeDetail, InterestPoint, ObjectSelection } from '@/core/interface/Catalog'
import { Excursion } from '@/core/interface/Excursion'
import { ExcursionCategory } from '@/core/interface/ExcursionCategory'
import { RootState } from '@/core/store'

interface ICatalogState {
  catalogType: catalogTypeDetail
  interestPoints: InterestPoint[]
  categories: string[]
  selectedCategories: ObjectSelection
  isFilterChanged: boolean
  playedAudioIds: string[]
  isPayedClient: boolean
  excursions: Excursion[] | []
  excursionCategories: ExcursionCategory[] | []
}

export const DEFAULT_CATEGORIES = ['B', 'M', 'C', 'S', 'P', 'Ponte', 'Other']

const initialState: ICatalogState = {
  catalogType: 'short',
  interestPoints: [],
  categories: [],
  selectedCategories: {
    category: 0,
    included: DEFAULT_CATEGORIES,
  }, //['10', '7', '5', '3'],
  isFilterChanged: false,
  playedAudioIds: [],
  isPayedClient: false,
  excursions: [],
  excursionCategories: [
    { id: 1, name: 'BY TIME' },
    { id: 2, name: 'BY TOPIC' },
    { id: 3, name: 'EXCLUSIVE' },
  ],
}

export const getPoints = createAsyncThunk(
  'getPoints',
  async ({ latitude, longitude, locale }: Omit<IGetPlaces, 'type'>, { getState }) => {
    const state = getState() as RootState

    const params = {
      latitude,
      longitude,
      locale,
      type: 'short', //state.catalogStore.catalogType,
    }

    const data = await getClosestObjects(params)

    return data
  },
)

export const getExcursionPoints = createAsyncThunk('getExcursionPoints', async () => {
  const data = await getExcursions('en')

  return data
})

export const getExcursionCategories = createAsyncThunk('getExcursionCategories', async () => {
  const data = await getCategories('en')

  return data
})

export const forecastState = createSlice({
  name: 'forecast',
  initialState,
  reducers: {
    setInterestPoints(state, action) {
      state.interestPoints = action.payload
    },
    setCatalogType(state, action: PayloadAction<catalogTypeDetail>) {
      state.catalogType = action.payload
    },
    setSelectedCategories(state, action) {
      if (typeof action.payload.category === 'number' && Array.isArray(action.payload.included)) {
        state.selectedCategories = action.payload
      } else {
        console.error('Categories set in the old way!')
      }
    },
    setFilterChanged(state, action: PayloadAction<boolean>) {
      state.isFilterChanged = action.payload
    },
    addPlayedAudio(state, action: PayloadAction<string>) {
      // Clone the state.playedAudioIds array to avoid mutating the original state
      const tmpIds = [...state.playedAudioIds]

      // Check if action.payload exists in playedAudioIds
      const index = tmpIds.indexOf(action.payload)

      if (index !== -1) {
        // If action.payload exists, remove it from its current position
        tmpIds.splice(index, 1)
      }

      // Add action.payload to the end of the array
      tmpIds.push(action.payload)

      state.playedAudioIds = tmpIds
    },
    setPayed(state, action: PayloadAction<boolean>) {
      state.isPayedClient = action.payload
    },
    setExcursions(state, action: PayloadAction<Excursion[]>) {
      state.excursions = action.payload
    },
  },
  extraReducers: (builder) => {
    //getPoints is defined higher
    builder.addCase(getPoints.fulfilled, (state, action: PayloadAction<InterestPoint[]>) => {
      if (action.payload && action.payload.length) {
        state.interestPoints = [...action.payload]

        const allCategories = action.payload?.reduce((acc, x) => {
          x.category.forEach((y) => {
            acc.push(y)
          })
          return acc
        }, [] as string[])

        const uniqueCategories = Array.from(new Set(allCategories))

        state.categories = [...uniqueCategories]
      }
    })
    builder.addCase(getExcursionPoints.fulfilled, (state, action: PayloadAction<Excursion[]>) => {
      if (action.payload && action.payload.length) {
        state.excursions = [...action.payload]
      }
    })
    builder.addCase(
      getExcursionCategories.fulfilled,
      (state, action: PayloadAction<ExcursionCategory[]>) => {
        if (action.payload && action.payload.length) {
          state.excursionCategories = [...action.payload]
        }
      },
    )
  },
})

export const {
  setInterestPoints,
  setCatalogType,
  setSelectedCategories,
  setFilterChanged,
  addPlayedAudio,
  setPayed,
  setExcursions,
} = forecastState.actions

export default forecastState.reducer
