import cns from 'classnames'
import { useEffect, useMemo, useRef } from 'react'

import { useScrollLock } from '@/core/hooks/useScrollLock'
import { useAppSelector } from '@/core/store'

interface IModalProps {
  name: string
  children: React.ReactElement[] | React.ReactElement
}

export const StrictModal: React.FC<IModalProps> = ({ name, children }) => {
  const { modal } = useAppSelector((store) => store.uiState)

  const modalRef = useRef<HTMLDivElement | null>(null)

  const { lockScroll, unlockScroll } = useScrollLock()

  const isActive = useMemo(() => {
    return modal === name
  }, [modal, name])

  useEffect(() => {
    if (isActive) {
      lockScroll()
    } else {
      unlockScroll()
    }

    return () => {
      unlockScroll()
    }
  }, [isActive])

  return (
    <>
      <div className={cns('modal-overlay', isActive && 'active')} />

      <div className={cns('modal', `modal--${name}`, isActive && 'active')} ref={modalRef}>
        <div className="modal-content">{children}</div>
      </div>
    </>
  )
}
