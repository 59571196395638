import { Coordinates, Language } from '@/core/interface'
import { buildParams } from '@/core/utils/api'

export const postPayment = async () => {
  return new Promise((resolve, reject) => {
    setTimeout(() => {
      resolve(true)
    }, 300)
  })
}
